import { inject } from "mobx-react";
import type { PageBlockInterface } from "../Block.component";
import { SocialIcons } from "@inferno/renderer-shared-ui";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { HeaderBlockValueResolver } from "@ihr-radioedit/inferno-core";
import { socialConfigAbstract } from "@inferno/renderer-shared-core";
import { isRight } from "fp-ts/lib/Either";
import "./Header.style.scss";
import type { Store } from "@inferno/renderer-shared-core";

interface HeaderProps extends PageBlockInterface {
  store?: Store;
}

export const Header = inject("store")((props: HeaderProps) => {
  const { store, block } = props;
  if (!store) {
    return null;
  }
  const { site, env } = store;
  const network = socialConfigAbstract(site.sections.social);

  // Extract block value
  const blockValue = HeaderBlockValueResolver.decode(block.value);
  const value = isRight(blockValue) ? blockValue.right : {};
  const image = value.background_image || env.DEFAULT_IMAGE;
  const title = value.title || site.sections.general?.name || "";
  const subtitle = value.subtitle || site.sections.general?.positioner || "";

  // Generate dynamic CSS for header image
  const css = `
        :root {
            --header-image: url("${formatImage(image, env.IMAGE_HOST)}?ops=resize(1600,0)");
            ${value.background_color ? `--header-background-color: ${value.background_color}` : ""}
        }
    `;

  return (
    <div className="component-header">
      <div className="positioner">
        <style dangerouslySetInnerHTML={{ __html: css }} />
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <SocialIcons networks={network} />
      </div>
    </div>
  );
});

export default Header;
